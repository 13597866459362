import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import akbcares from "../../images/AkebiaCares-logo.png"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import quoteimg from "../../images/hp/access/Connie.png"

import CalloutIcon1 from "../../images/icons/icon-callout-hp-efficacy.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-safety.png"
import TranscriptAccordion from "../../components/shared/transcript-accordion"
import Derrick from "../../images/hp/patient-id/derrick.png"
import Maria from "../../images/hp/patient-id/maria.png"

const anchorClick = event => {
    event.preventDefault();
    event.stopPropagation();

    const bounds = document.getElementById('trial-design').getBoundingClientRect();
    let scrollPosition = window.pageYOffset + bounds.top;

    // Adjust for the height of the sticky header 
    let stickyHdr;
    setTimeout(function () {
        if (window.matchMedia("(min-width: 768px)").matches) {
            stickyHdr = document.getElementById('site-navigation').getBoundingClientRect().height;
        }
        else {
            stickyHdr = document.getElementById('hpp-navigation').getBoundingClientRect().height;
        }
        scrollPosition = scrollPosition - stickyHdr;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }, 100);
};

const hppPid = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v7.0)"
        jobCodeDate="07/23"
        references={[
            <>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.</>,
            <>Kidney Disease: Improving Global Outcomes (KDIGO) CKD-MBD Update Work Group. KDIGO 2017 clinical practice guideline update for the diagnosis, evaluation, prevention, and treatment of chronic kidney disease-mineral and bone disorder (CKD-MBD). <em>Kidney Int Suppl</em>. 2017;7(1):1-59. doi:10.1016/j.kisu.2017.04.001</>,
            <>Data on File 1, Akebia Therapeutics, Inc.</>,
            <>Umanath K, Sika M, Niecestro R, et al; Collaborative Study Group. Rationale and study design of a three period, 58-week trial of ferric citrate as a phosphate binder in patients with ESRD on dialysis. <em>Hemodial Int.</em> 2013;17(1):67-74. doi:10.1111/j.1542-4758.2012.00711.x</>
        ]}>
        <Helmet>
            <html lang="en" />
            <link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/patient-profiles" />
            <body id="hpp-hcp-pid" className="hpp" />
        </Helmet>

        <Seo title="Patient Profiles" description="Find out if AURYXIA® (ferric citrate) tablets are appropriate for your Patients. Read Important Safety Information and Full Prescribing information on this page." />
        <div className="back-gradient">



        <section id="patient-id" className="col-10">
            <div className="flex-row">
                <div>
                    <h1>Who may be appropriate for&nbsp;AURYXIA?</h1>
                    <p>There are many options when it comes to prescribing a treatment for hyperphosphatemia.</p>
                    {/* <br className="desktop" /> */}
                    <p>AURYXIA is contraindicated in patients with iron-overload syndromes (eg, hemochromatosis).</p>
                </div>
                <div>
                    <h2 className="hpp">Patients to consider for&nbsp;AURYXIA</h2>

                    <p>Adults with CKD receiving dialysis in need of phosphorus control who:</p>

                    <ul>
                        <li>Are above the target phosphorus range per clinical guidance <em>or</em></li>
                        <li>May be non-compliant with their current binder <em>or</em></li>
                        <li>Prefer non-chewable tablets <em>or</em></li>
                        <li>Have concerns about calcium-based binders <em>or</em></li>
                        <li>Are on in-center or home dialysis</li>
                    </ul>
                    <p className="footnote">CKD=chronic kidney disease.</p>
                </div>

            </div>
        </section>
        <section id="derrick">
            <TranscriptAccordion
                siteAudience="hpp"
                transcriptTitle="Learn about Derrick"
                transcriptContent={<>
                <div className="flex-row">
                    <div className="small-only patient-img">
                        <img src={Derrick} alt="A male patient in a dark gray t-shirt sits with his forearms on his knees" />
                        <p>Hypothetical patient profile. Not actual patient.</p>
                    </div>
                    <div>
                        <h4 className="hpp">About Derrick:</h4>
                        <ul>
                            <li>65-year-old retired firefighter</li>
                            <li>Diagnosed with hyperphosphatemia due to CKD 6 months ago</li>
                            <li>Enjoys cooking and spending time with his 2 grandchildren</li>
                        </ul>
                        <h4 className="hpp">About His Hyperphosphatemia Management:</h4>
                        <ul>
                            <li>Derrick has been taking a phosphate binder for the past 3 months and has struggled to reach 
                            his goal</li>
                
                                <ul className="nested">
                                    <li>HCP is not pleased with his phosphorus levels (6.5 mg/dL)</li>
                                    <li>His current phosphate binder dose requires 9 pills a day</li>
                                    <li>Phosphorus levels are still at 6.5 mg/dL</li>
                                    <li>HCP is looking for an alternative treatment option</li>
                                </ul>
                
                        </ul>
                        <h4 className="hpp">Medical History:</h4>
                        <ul>
                            <li>Currently undergoing in-center dialysis treatment</li>
                            <li>HCP concerned about additional phosphate binder dose increases</li>
                            <li>Prefers swallowing his medications</li>
                            <li>Receiving intravenous (IV) iron</li>
                            <li>No history of iron overload syndrome</li>
                        </ul>
                        <h4 className="hpp">Recent Lab Results*:</h4>
                        <ul>
                            <li>Phosphorus levels have remained around 6.5 mg/dL over the past 3 months. Most recent level was 6.3 mg/dL</li>
                            <li>PTH – 400 pg/mL</li>
                            <li>Calcium – 9.5 mg/dL</li>
                            <li>TSAT was at 25% 6 months ago and is now at 32%</li>
                            <li>Ferritin has ranged from 550 ng/mL to 650 ng/mL over the past 6 months</li>
                            <li>Hemoglobin increased from 12 g/dL to 12.5 g/dL over the past 6 months</li>
                        </ul>
                        <p>PTH=parathyroid hormone; TSAT=transferrin saturation.</p>
                        <p className="footnote"><span className="hang">*</span>Regular laboratory monitoring is part of routine clinical practice in patients with hyperphosphatemia due to CKD.<sup>2</sup></p>
                    </div>
                    <div className="large-only patient-img">
                        <img src={Derrick} alt="A male patient in a dark gray t-shirt sits with his forearms on his knees" />
                        <p>Hypothetical patient profile. Not actual patient.</p>
                    </div>
                </div>
                </>} />
        </section>
        <section id="maria">
            <TranscriptAccordion
                siteAudience="hpp"
                transcriptTitle="Learn about Maria"
                transcriptContent={<>
                <div className="flex-row">
                    <div className="small-only patient-img">
                        <img src={Maria} alt="A female patient in a black blouse stands with her hands on her hips" />
                        <p>Hypothetical patient profile. Not actual patient.</p>
                    </div>
                    <div>
                        <h4 className="hpp">About Maria:</h4>
                        <ul>
                            <li>45 years old</li>
                            <li>Works full-time</li>
                            <li>Recently diagnosed with hyperphosphatemia due to CKD</li>
                            <li>Enjoys outdoor activities</li>
                        </ul>
                        <h4 className="hpp">About Her Hyperphosphatemia Management:</h4>
                        <ul>
                            <li>Not currently on any phosphate binder</li>
                            <ul className="nested">
                                <li>Phosphorus levels at 7.2 mg/dL</li>
                            </ul>
                            <li>Following prescribed low-phosphorus diet</li>
                
                        </ul>
                        <h4 className="hpp">Medical History:</h4>
                        <ul>
                            <li>Initiated peritoneal dialysis 1 month ago</li>
                            <li>Prefers swallowing her medications</li>
                            <ul className="nested">
                                <li>Concerned with her teeth yellowing and/or staining</li>
                            </ul>
                            <li>Receiving intravenous (IV) iron</li>
                            <li>No history of iron overload syndrome</li>
                        </ul>
                        <h4 className="hpp">Recent Lab Results*:</h4>
                        <ul>
                            <li>Phosphorus trending upward for the past 6 months from 6.5 mg/dL to 7.2 mg/dL</li>
                            <li>PTH – 450 pg/mL</li>
                            <li>Calcium – 9.5 mg/dL</li>
                            <li>TSAT at 38%</li>
                            <li>Ferritin at 575 ng/mL</li>
                            <li>Hemoglobin – 11 g/dL</li>
                        </ul>
                        <p>PTH=parathyroid hormone; TSAT=transferrin saturation.</p>
                        <p className="footnote"><span className="hang">*</span>Regular laboratory monitoring is part of routine clinical practice in patients with hyperphosphatemia due to CKD.<sup>2</sup></p>
                    </div>
                    <div className="large-only patient-img">
                        <img src={Maria} alt="A female patient in a black blouse stands with her hands on her hips" />
                        <p>Hypothetical patient profile. Not actual patient.</p>
                    </div>
                </div>
                </>} />
        </section>
        <section id="quote-slab" className="flood-hp col-10">
            <div className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="100"
                data-sal-ease="ease-out-expo">
                <div>
                    <img src={quoteimg} alt="A headshot of a smiling female patient in a striped shirt" width="268" />
                </div>
                <div>
                    <blockquote>

                        <div className="blockquote_div">
                            <p className="hpp desktop_span"><span>&#x201C;</span></p><p className="hpp"><span className="mobile_span">&#x201C; </span>When I see a patient who has uncontrolled phosphorus, I will typically take the opportunity of starting the patient on AURYXIA.<span>&#x201d;</span></p>

                        </div>


                        <cite>– <strong>Constance Nicastro-Bowman</strong>, CNP Nurse Practitioner</cite>
                        {/* <Link to="/hyperphosphatemia/resources/" className="gradient-link hpp">Hear from your peers on why they prescribe AURYXIA</Link> */}
                        <Link to="/hyperphosphatemia/resources/" className="hpp">Hear from your peers on why they prescribe AURYXIA</Link>
                    </blockquote>
                </div>
            </div>
        </section>
        
        </div>

        <EopCalloutCards
            siteAudience="hpp"
            callOneClass={"highlight"}
            callOneContent={
                <>
                   <div>
                        <img src={CalloutIcon1} alt="" width="98" height="98" />
                        <h3>See how AURYXIA helped patients reach their target goals</h3>
                   </div>
                   <div>
                        <p>AURYXIA helped patients reach and stay in the range of <span className="nowrap">3.5-5.5 mg/dL</span> during a <span className="nowrap">56-week</span> trial.<sup>1</sup><br />
                            Patients had a mean serum phosphorus level of 7.41 mg/dL at baseline and <span className="nowrap">4.88 mg/dL</span> at Week 56.<sup>3</sup> </p>
                        <a href="/hyperphosphatemia/ferric-citrate-efficacy/" className="cta hpp"><span>EXAMINE EFFICACY</span></a>
                        <p><span className="gradient-link anchor-link hpp"
                            onClick={anchorClick} onKeyDown={anchorClick} role="button" tabIndex={0}>See trial design <KeyboardArrowDown viewBox="6 6 12 12" /></span></p>
                   </div>
                </>
            }
            callTwoClass={"default"}
            callTwoLink={"/hyperphosphatemia/ferric-citrate-reactions/"}
            callTwoContent={
                <>
                    <div>
                        <img src={CalloutIcon2} alt="" width="98" height="98" />
                        <h3>Get to know the proven safety profile of AURYXIA</h3>
                        <p>See the product safety and tolerability profile for AURYXIA in a <span className="nowrap">52-week</span> trial.<sup>1</sup></p>
                    </div>
                    <div>
                        <button className="cta hpp"><span>EXPLORE SAFETY</span></button>
                        <p>&nbsp;</p>
                    </div>
                </>
            }
        />

        <section id="crosslink-slab" className="akb akb_moa_section">
            <div className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo">
                <div>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>Your partner in helping patients access the medication they need.</h3>
                    <p className="boldTxt">Connect with a personal Case Manager today!</p>
                    <p className="med_width">AkebiaCares personal Case Managers are standing by live to answer your call and help your patients find coverage.
                  </p>
                    <ul className="info_ul">
                        <li>1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)</li>
                        <li>Monday&nbsp;-&nbsp;Friday</li>
                        <li className="hours_li">8<span>AM</span>&nbsp;‐&nbsp;8<span>PM</span>&nbsp;EST</li>
                    </ul>

                    <a href="https://www.akebiacareshcp.com/" className="cta akb"><span>FIND SUPPORT OPTIONS</span></a>

                </div>
            </div>
        </section>

        <section id="interested-slab" className="col-6 center">
            <div className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo">
                <div>
                    <h3>You may also be interested&nbsp;in: </h3>
                    <p><Link to="/hyperphosphatemia/mechanism-of-action/" className="gradient-link hpp">MECHANISM OF ACTION</Link></p>
                </div>
            </div>
        </section>

        <section id="trial-design" className="flood-hp col-10">
            <div calss="flex-row">
                <div>
                    <p><strong>Trial design<sup>1,4</sup></strong></p>
                    <p>A multicenter, randomized, open‐label trial evaluated the ability of AURYXIA to lower serum phosphorus in patients with CKD on dialysis over 56 weeks. Eligible patients had serum ferritin &#x003C;1000 ng/mL, serum TSAT &#x003C;50%, and serum phosphorus ≥2.5 and ≤8.0 mg/dL at the screening visit. The safety and efficacy of AURYXIA were studied in the 52‐week active‐controlled period (AURYXIA n=292, Active Control n=149), then AURYXIA patients were re‐randomized to either continue AURYXIA treatment or receive placebo during the placebo‐controlled period, weeks 52‐56 (AURYXIA n=96, placebo n=96). The primary endpoint was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo. The key secondary endpoint was the change in serum phosphorus from baseline (Week 0) to Week 52 between AURYXIA and Active Control.</p>
                    <p className="footnote">TSAT=transferrin saturation; Active Control=sevelamer carbonate and/or calcium acetate.</p>
                </div>
            </div>
        </section>

    </HPPLayout>
)

export default hppPid